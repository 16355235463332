import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Router'ı import ediyoruz
import { createPinia } from 'pinia'

const app = createApp(App);
const pinia = createPinia()
app.use(pinia);
app.use(router); // Vue uygulamasına Router'ı ekliyoruz

app.mount('#app');