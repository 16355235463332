<template>
  <div>
    <div>
      <input v-show="false" v-model="question" placeholder="Hangi doktorlar ile çalışıyorsunuz?"
        style="width: 80%;" />
    </div>
    <div v-if="1 > 2">
      <h2>Response:</h2>
      <p>{{ responseText }}</p>
    </div>
    <div>
      <div v-show="!isPlaying" ref="initialAnimation" class="lottie-container" @mousedown="startRecording"
        @mouseup="stopRecording" @touchstart="startRecording" @touchend="stopRecording"></div>
      <div v-show="isPlaying" ref="playingAnimation" class="lottie-container2"></div>

      <div v-if="isRecording" class="recording-timer">{{ formatTime(recordingTime) }}</div>
      <span style="color:blueviolet">
        {{ question }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import lottie from 'lottie-web';

export default defineComponent({
  name: 'SpeechAssistant',
  setup() {
    // Reaktif değişkenler
    const mediaRecorder = ref<MediaRecorder | null>(null);
    const audioChunks = ref<Blob[]>([]);
    const audioData = ref<Blob | null>(null);
    const responseText = ref<string>('');
    const audioSrc = ref<string>('');
    const isRecording = ref<boolean>(false);
    const isPlaying = ref<boolean>(false);
    const question = ref<string>('');
    const recordingTime = ref<number>(0);
    let recordingInterval: number | null = null;
    const threadId = 'thread_BPMjS5HZnDtMQB8lCl5ySPxV';

    // Ref'ler
    const initialAnimation = ref<HTMLElement | null>(null);
    const playingAnimation = ref<HTMLElement | null>(null);

    const lottieAnimations = {
      initial: null as any,
      playing: null as any,
    };

    let recognition: any = null;

    onMounted(() => {
      initLottieAnimations();
      initSpeechRecognition();
      console.log(process.env.VUE_APP_VERSION)
    });

    const initLottieAnimations = () => {
      if (initialAnimation.value && playingAnimation.value) {
        lottieAnimations.initial = lottie.loadAnimation({
          container: initialAnimation.value,
          renderer: 'svg',
          loop: true,
          autoplay: false,
          path: '../animation.json',
        });

        lottieAnimations.playing = lottie.loadAnimation({
          container: playingAnimation.value,
          renderer: 'svg',
          loop: true,
          autoplay: false,
          path: '../animation-play.json',
        });
      }
    };

    const initSpeechRecognition = () => {
      const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
      recognition = new SpeechRecognition();
      recognition.maxAlternatives = 10;
      recognition.lang = "tr-TR";
      recognition.interimResults = true;
      recognition.continuous = true;

      recognition.onresult = (event: { results: { transcript: any; }[][]; }) => {
        const transcript = Array.from(event.results)
          .map(result => result[0].transcript)
          .join('');
        question.value = transcript;
      };

      recognition.onerror = (event: any) => {
        console.error('Speech recognition error detected:', event.error);
      };
    };

    const startRecording = async () => {
      question.value = "";
      console.log('Start recording');
      if (lottieAnimations.initial) lottieAnimations.initial.play();
      const audioPlayer = new Audio('../sounds/recording_start.mp3');
      audioPlayer.play();
      isRecording.value = true;
      recordingTime.value = 0;
      recordingInterval = setInterval(() => {
        recordingTime.value++;
      }, 1000);

      if (recognition) recognition.start();

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.value = new MediaRecorder(stream);

      if (mediaRecorder.value) {
        mediaRecorder.value.ondataavailable = (event: BlobEvent) => {
          audioChunks.value.push(event.data);
        };

        mediaRecorder.value.onstop = () => {
          audioData.value = new Blob(audioChunks.value, { type: 'audio/wav' });
          audioChunks.value = [];
          console.log('Recording stopped, audio data available');
          sendAudio();
        };

        mediaRecorder.value.start();
        isRecording.value = true;
      }
    };

    const stopRecording = () => {
      if (lottieAnimations.initial) lottieAnimations.initial.stop();
      isRecording.value = false;
      if (recordingInterval) clearInterval(recordingInterval);
      if (recognition) recognition.stop();

      console.log('Stop recording');
      if (mediaRecorder.value) {
        mediaRecorder.value.stop();
        isRecording.value = false;
        isPlaying.value = true;
      }
    };

    const sendAudio = async () => {
      if (!audioData.value) {
        alert('Please record your voice first.');
        return;
      }

      const apiUrl = process.env.NODE_ENV === 'production'
        ? process.env.VUE_APP_VERCEL_API_URL
        : process.env.VUE_APP_LOCAL_API_URL;

      audioSrc.value = `${apiUrl}stream/${threadId}/${encodeURIComponent(question.value)}`;
      console.log(audioSrc.value);
      playAudioStreamWithLottie(audioSrc.value);
    };

    const playAudioStreamWithLottie = (audioSrc: string) => {
      const audioPlayer = new Audio(audioSrc);
      audioPlayer.play().then(() => {
        isPlaying.value = true;
        if (lottieAnimations.playing) lottieAnimations.playing.play();
      }).catch(error => console.error('Error playing audio stream:', error));

      audioPlayer.addEventListener('ended', () => {
        if (lottieAnimations.playing) lottieAnimations.playing.stop();
        isPlaying.value = false;
      });
    };

    const formatTime = (seconds: number): string => {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return {
      initialAnimation,
      playingAnimation,
      question,
      isRecording,
      isPlaying,
      startRecording,
      stopRecording,
      formatTime,
    };
  }
});
</script>

<style scoped>
button {
  margin: 10px;
  align-items: center;
}

input {
  margin-bottom: 20px;
  display: block;
}

.lottie-container {
  width: 100%;
  height: 160px;
}

.lottie-container2 {
  width: 100%;
  height: 160px;
}

.recording-timer {
  color: darkgray
}
</style>
