<template>
 
  <UserDetail />
  <component :is="selectedAssistant" />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import OptionsMenu from '@/components/OptionsMenu.vue';
import ChatAssistant from '@/components/ChatAssistant.vue';
import SpeechAssistant from '@/components/SpeechAssistant.vue';
import UserDetail from '@/components/UserDetail.vue';
// <OptionsMenu @selectionChanged="handleSelection" />
export default defineComponent({
  name: 'AssistantView',
  components: {
    OptionsMenu,
    ChatAssistant,
    SpeechAssistant,
    UserDetail,
  },
  setup() {
    // selectedAssistant'i ref olarak tanımlıyoruz
    const selectedAssistant = ref('ChatAssistant'); // Varsayılan olarak ChatAssistant'ı gösteriyoruz.

    // handleSelection fonksiyonu ile seçimi güncelliyoruz
    const handleSelection = (selection: string) => {
      if (selection === 'chat') {
        selectedAssistant.value = 'ChatAssistant';
      } else if (selection === 'speech') {
        selectedAssistant.value = 'SpeechAssistant';
      }
    };

    return {
      selectedAssistant,
      handleSelection,
    };
  },
});
</script>

<style scoped>
/* İsteğe bağlı ek stil tanımları */
</style>
