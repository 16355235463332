<template>
  
  <div id="login-screen" class="bg-white p-8 rounded-2xl border border-gray-200 max-w-sm w-full mx-auto my-16" v-if="!isLoggedIn">
    <h2 class="text-2xl font-bold text-center text-gray-800 mb-6">Giriş Yap</h2>
    <form id="login-form" @submit.prevent="handleLogin">
      <div class="mb-4">
        <label for="tc" class="block text-gray-700 font-medium mb-2">Öğrenci TC Kimlik No (Son 4 Hane)</label>
        <input 
          type="text" 
          id="tc" 
          v-model="tc" 
          maxlength="4" 
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
          placeholder="1234" 
          required
        />
      </div>
      <div class="mb-6">
        <label for="phone" class="block text-gray-700 font-medium mb-2">Veli Cep Telefonu Numarası</label>
        <MaskInput
          mask="(###) ### ## ##" 
          v-model="phone" 
          type="text" 
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
          placeholder="(555) 555 55 55" 
          required
        />
      </div>
      <button 
        type="submit" 
        class="w-full bg-gray-900 text-white px-6 py-3 rounded-lg shadow-md hover:bg-gray-950"
      >
        Giriş Yap
      </button>
    </form>
    <p v-if="errorMessage" class="text-red-500 mt-4 text-center">{{ errorMessage }}</p>
  </div>

  <div id="otp-screen" class="bg-white p-8 rounded-2xl border border-gray-200 max-w-sm w-full mx-auto my-16" v-else>
    <h2 class="text-2xl font-bold text-center text-gray-800 mb-6">OTP Onayla</h2>
    <form id="otp-form" @submit.prevent="handleOtp">
      <div class="mb-6">
        <label for="otp" class="block text-gray-700 font-medium mb-2">Tek Seferlik Şifre</label>
        <input 
          type="text" 
          id="otp" 
          v-model="otp" 
          class="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" 
          placeholder="OTP" 
          required 
        />
      </div>
      <button 
        type="submit" 
        class="w-full bg-gray-900 text-white px-6 py-3 rounded-lg shadow-md hover:bg-gray-950 transition duration-300"
      >
        Onayla
      </button>
    </form>
    <p v-if="otpErrorMessage" class="text-red-500 mt-4 text-center">{{ otpErrorMessage }}</p>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '../stores/auth';
import { MaskInput } from 'vue-3-mask';

export default defineComponent({
  name: 'LoginView',
  components: {
    MaskInput,
  },
  setup() {
    const router = useRouter();
    const authStore = useAuthStore();
    const tc = ref('');
    const phone = ref('');
    const otp = ref('');
    const isLoggedIn = ref(false);
    const errorMessage = ref('');
    const otpErrorMessage = ref('');

    const dummyCredentials = {
      tc: '1234',
      phone: '(555) 555 55 55',
      otp: '0000',
    };

    const handleLogin = () => {
      if (tc.value === dummyCredentials.tc && phone.value === dummyCredentials.phone) {
        isLoggedIn.value = true;
      } else {
        errorMessage.value = 'Geçersiz TC Kimlik Numarası veya Telefon Numarası';
      }
    };

    const handleOtp = () => {
      if (otp.value === dummyCredentials.otp) {
        authStore.login();
        router.push('/teacher-list');
      } else {
        otpErrorMessage.value = 'Geçersiz OTP';
      }
    };

    return {
      tc,
      phone,
      otp,
      isLoggedIn,
      errorMessage,
      otpErrorMessage,
      handleLogin,
      handleOtp,
    };
  },
});
</script>

<style scoped>
/* İsteğe bağlı ek stil tanımları */
</style>
