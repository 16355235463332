import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: localStorage.getItem('isAuthenticated') === 'true',
  }),
  actions: {
    login() {
      this.isAuthenticated = true;
      localStorage.setItem('isAuthenticated', 'true'); // Oturum bilgisini localStorage'da sakla
    },
    logout() {
      this.isAuthenticated = false;
      localStorage.setItem('isAuthenticated', 'false'); // Oturum bilgisini localStorage'dan kaldır
    },
  },
});
