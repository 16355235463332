<template>
  
  <Popover class="relative text-center">
    <PopoverButton class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
      <span>Options</span>
      <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
    </PopoverButton>

    <transition enter-active-class="transition ease-out duration-100" enter-from-class="opacity-0 translate-y-1"
      enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150"
      leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
      <PopoverPanel let:close v-slot="{ close }" class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4">
        <div
          class="text-left w-screen max-w-md flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
          <div class="p-4">
            <div v-for="item in solutions" :key="item.name"
              class="group relative flex gap-x-6 rounded-lg p-4 hover:bg-gray-50">
              <div
                class="mt-1 flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                <component :is="item.icon" class="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                  aria-hidden="true" />
              </div>
              <div>
                <button @click="close(); selectOption(item.action); " class="font-semibold text-gray-900">
                  {{ item.name }}
                  <span class="absolute inset-0" />
                </button>
                <p class="mt-1 text-gray-600">{{ item.description }}</p>
              </div>
            </div>
          </div>
          <div class="grid grid-cols-2 divide-x divide-gray-900/5 bg-gray-50">
            <a v-for="item in callsToAction" :key="item.name" :href="item.href"
              class="flex items-center justify-center gap-x-2.5 p-3 font-semibold text-gray-900 hover:bg-gray-100">
              <component :is="item.icon" class="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
              {{ item.name }}
            </a>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>

<script lang="ts">
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { defineComponent, ref } from 'vue';
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon, SpeakerWaveIcon, ChatBubbleLeftRightIcon } from '@heroicons/vue/20/solid';
import { ArrowPathIcon, ChatBubbleBottomCenterIcon } from '@heroicons/vue/24/outline';

export default defineComponent({
  name: 'HelloWorld',
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    ChevronDownIcon,
    PhoneIcon,
    PlayCircleIcon,
    ArrowPathIcon,
    ChatBubbleLeftRightIcon,
    ChatBubbleBottomCenterIcon,
    SpeakerWaveIcon
  },
  props: {
    msg: {
      type: String,
      required: true
    }
  },
  emits: ['selectionChanged'],
  setup(_, { emit }) {
    const solutions = [
      { name: 'Chat', description: 'Fast response', action: 'chat', icon: ChatBubbleLeftRightIcon },
      { name: 'Talk', description: 'Latency on speech responses', action: 'speech', icon: SpeakerWaveIcon },
    ];

    const callsToAction = [
      { name: 'Watch demo', href: '#', icon: PlayCircleIcon },
      { name: 'Contact sales', href: '#', icon: PhoneIcon },
    ];

    const selectOption = (option: string) => {
      emit('selectionChanged', option);
    };

    return { solutions, callsToAction, selectOption };
  }
});
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
