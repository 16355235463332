import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-5c55b9d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col sm:flex-row w-full max-w-7xl mx-auto bg-zinc-50 shadow-lg p-4 mb-0 rounded-t-md border-b" }
const _hoisted_2 = {
  key: 0,
  class: "flex sm:flex-row sm:space-x-4 w-full"
}
const _hoisted_3 = { class: "flex items-center space-x-4 w-full" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "sm:flex-1" }
const _hoisted_6 = { class: "text-lg font-semibold text-indigo-600" }
const _hoisted_7 = { class: "text-zinc-500 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              src: _ctx.user ? require(`@/assets/${_ctx.user.logoUrl}`) : '',
              alt: "Logo",
              class: "w-20 h-20 rounded-full border"
            }, null, 8, _hoisted_4),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h2", _hoisted_6, _toDisplayString(_ctx.user.name), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.user.description), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}