<template>
  <div id="app">
    <PageHeader />  <!-- Header bileşenini kullanıyoruz -->

    <main>
      <router-view></router-view>
    </main>
    <div class="container px-6 pb-8 mx-auto">
      <div class="flex flex-col items-center text-center ">
        <p class="max-w-lg mx-auto mt-4 text-gray-500 dark:text-gray-400">SchoolMeet.AI, <a href="https://equityroadventures.com" class=" underline font-medium">Equity Road Ventures</a> portföyünde yer alan bir girişimidir.</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import PageHeader from './components/PageHeader.vue';


export default defineComponent({
  components: {
    PageHeader,
  },
});
</script>

<style scoped>
#app {
  font-family: Jost, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
